import styles from "./Intro.module.scss";
//import logo from "./../../images/tabrecta.png";
//import logo from "./../../images/piqray.png";
//import logo from "./../../images/kisqali.png";
import logo from "./../../images/tafmek.png";
import bg from "./../../images/intro.png";

export default function Intro({ children, nav }) {
  return (
    <div className={styles.intro}>
      <img src={bg} className={styles.bg} />
      <img src={logo} className={styles.logo} />
      <button
        className={styles.begin}
        onClick={() => {
          nav();
        }}
      >
        BEGIN
      </button>
    </div>
  );
}
